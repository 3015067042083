// languages.ts
const languages = {
    fr: {
        footer_button_contact: "Contactez-moi",
        homepage_title_job_name: "Développeur web",
        homepage_title_projects: "Projets",
        homepage_title_about_me: "À propos de moi",
        homepage_title_education: "Formation",
        homepage_about_me_age: "21 ans",
        homepage_about_me_from: "Français",
        homepage_about_me_activity: "Étudiant en ingénierie logicielle",
        footer_language_select: "EN",
        navbar_language_select: "EN",
        navbar_title_projects: "Projets",
        navbar_title_about_me: "À propos de moi",
        navbar_title_contact: "Contact",
        project_taskmanager_title: "Gestionnaire de services",
        project_taskmanager_description: "Site web développé pour simplifier l'organisation des tâches ménagères dans une école. Permet à un responsable de répartir les tâches par jour, imprimer l'emploi du temps, et faciliter la communication avec les élèves. Projet réalisé pour un établissement scolaire.",
        project_letsgetrich_title: "LetsGetRich",
        project_letsgetrich_description: "Site web également développé pour simplifier la gestion d'un budget pour une personne lambda. Cette gestion comprend les mêmes fonctionnalités que le projet plusDeThunes, à savoir la gestion de différents comptes bancaires, l'ajout des transactions, la création de catégories de dépenses, l'affectation des transactions à ces catégories... Projet réalisé tout seul dans l'objectif d'apprendre le Symfony, ce fut mon premier projet avec cette technologie.",
        project_plusdethunes_title: "PlusDeThune",
        project_plusdethunes_description: "Site web développé pour simplifier la gestion d'un budget pour une personne lambda. Cette gestion comprend les différents comptes bancaires, l'ajout des transactions, la création de catégories de dépenses, l'affectation des transactions à ces catégories... Projet réalisé dans le cadre scolaire, j'ai été responsable de la création de la partie back-end (API Rest).",
        project_letsgettipsy_title: "LetsGetTipsy",
        project_letsgettipsy_description: "Site web développé en s'appuyant sur l'API thecocktaildb. Le site permettait la recherche de cocktail par nom et ingrédient, l'affichage des recettes et la possibilité de mettre des cocktails en favoris. ",
        project_count_title: "Count",
        project_count_description: "Application mobile en cours de développement. Elle permettra la création de compétitions publiques ou privées, dans lesquelles les membres pourront tenir à jour leur score et se comparer avec les autres membres. Projet réalisé dans le cadre scolaire.",
        contact_form_title: 'Envoyez-moi un message',
        contact_form_firstname_label: 'Votre prenom',
        contact_form_lastname_label: 'Votre nom',
        contact_form_email_label: 'Votre adresse email',
        contact_form_message_label: 'Votre message',
        contact_form_submit_label: 'Envoyer le message',
        contact_form_message_placeholder: 'Bonjour...',
        contact_form_message_success: 'Votre message a été envoyé avec succès !',
        contact_form_message_failed: 'Il y a eu une erreur lors de l\'envoi de votre message, vous pouvez l\'envoyer directement à mon adresse email.',
        contact_information_title: 'ou prenez mes informations de contact !',
        contact_information_email_title: 'Email :',
        contact_information_phone_title: 'Numéro de téléphone :',
        contact_information_address_title: 'Adresse :',
        contact_information_linkedin_title: 'LinkedIn :',
        about_me_who_text: 'Bienvenue sur mon portfolio ! Je suis Esteban GOMEZ, un développeur français de 21 ans. La musique guide mes lignes de code, tandis que mon intérêt pour le design se traduit par des interfaces utilisateur sobres et esthétiques. Explorez mon portfolio, une fusion de passion et de technologie, constamment en évolution.',
        about_me_studies_title: 'Mon parcours',
        about_me_studies_bac_title: 'Bac scientifique',
        about_me_studies_bac_where: 'Lycée Édouard Branly',
        about_me_studies_iut_title: 'DUT informatique',
        about_me_studies_iut_where: 'IUT de Nantes',
        about_me_studies_imt_title: 'Formation d\'Ingénieur spécialité ingénierie logicielle (FIL)',
        about_me_studies_imt_where: 'IMT Atlantique',
        about_me_job_title: 'Mes expériences',
        about_me_job_pladis_title: 'Apprentis techniciens informatiques',
        about_me_job_kosmos_title: 'Apprentis ingénieur logiciel',
    },
    en: {
        footer_button_contact: "Get in Touch",
        homepage_title_job_name: "Web developer",
        homepage_title_projects: "Projects",
        homepage_title_about_me: "About me",
        homepage_title_education: "Education",
        homepage_about_me_age: "21 years old",
        homepage_about_me_from: "French",
        homepage_about_me_activity: "Software engineering student",
        footer_language_select: "FR",
        navbar_language_select: "FR",
        navbar_title_projects: "Projects",
        navbar_title_about_me: "About me",
        navbar_title_contact: "Contact",
        project_taskmanager_title: "Task manager",
        project_taskmanager_description: "Website developed to simplify the organisation of household chores in a school. It enables the person in charge to allocate tasks by day, print out the timetable and facilitate communication with pupils. Project carried out for a school.",
        project_letsgetrich_title: "LetsGetRich",
        project_letsgetrich_description: "Website also developed to simplify budget management for the average person. This management includes the same features as the plusDeThunes project, i.e. managing different bank accounts, adding transactions, creating spending categories, assigning transactions to these categories, etc. I did this project on my own with the aim of learning Symfony, and it was my first project using this technology.",
        project_plusdethunes_title: "PlusDeThune",
        project_plusdethunes_description: "Website developed to simplify budget management for the average person. This management includes the various bank accounts, adding transactions, creating spending categories, allocating transactions to these categories, etc. This project was carried out as part of a school project, and I was responsible for creating the back-end (API Rest).",
        project_letsgettipsy_title: "LetsGetTipsy",
        project_letsgettipsy_description: "Website developed using thecocktaildb API. The site allowed users to search for cocktails by name and ingredient, display recipes and bookmark cocktails.",
        project_count_title: "Count",
        project_count_description: "Mobile application currently under development. It will enable the creation of public or private competitions, in which members will be able to keep their score up to date and compare themselves with other members. Project carried out in a school setting.",
        contact_form_title: 'Send me a message',
        contact_form_firstname_label: 'Your firstname',
        contact_form_lastname_label: 'Your lastname',
        contact_form_email_label: 'Your email address',
        contact_form_message_label: 'Your message',
        contact_form_submit_label: 'Send message',
        contact_form_message_placeholder: 'Hello...',
        contact_form_message_success: 'Your message has been sent successfully!',
        contact_form_message_failed: 'There was an error sending your message, you can send it directly to my email address.',
        contact_information_title: 'or take my contact details!',
        contact_information_email_title: 'Email:',
        contact_information_phone_title: 'Phone number:',
        contact_information_address_title: 'Address:',
        contact_information_linkedin_title: 'LinkedIn:',
        about_me_who_text: 'Welcome to my portfolio! I\'m Esteban GOMEZ, a 21-year-old French developer. Music guides my lines of code, while my interest in design translates into sleek and aesthetic user interfaces. Explore my portfolio, a fusion of passion and technology, constantly evolving.',
        about_me_studies_title: 'My education',
        about_me_studies_bac_title: 'Scientific baccalauréat',
        about_me_studies_bac_where: 'Édouard Branly High School',
        about_me_studies_iut_title: 'Equivalent to an HND in IT',
        about_me_studies_iut_where: 'Nantes IUT',
        about_me_studies_imt_title: 'Engineering degree specialising in software engineering (FIL)',
        about_me_studies_imt_where: 'IMT Atlantique',
        about_me_job_title: 'My experiences',
        about_me_job_pladis_title: 'Apprentice IT Technician',
        about_me_job_kosmos_title: 'Apprentice software engineers',
    },
};

export default languages;
